@charset "utf-8";
/**
   _____                 _     _        
  / ____|               | |   | |       
 | |        __ _   ___  | |_  | |   ___ 
 | |       / _` | / __| | __| | |  / _ \
 | |____  | (_| | \__ \ | |_  | | |  __/
  \_____|  \__,_| |___/  \__| |_|  \___|
 ----------------------------------------
 * Castle's NewUI Stylesheet
 * Last Update: 2022/04/22 [0.9.7]
 * Author: ohmyga (https://ohmyga.cn)
 * GitHub: https://github.com/bakaomg/castle-Typecho-Theme/
 * LICENSE: GPL V3 (https://www.gnu.org/licenses/gpl-3.0.html)
 **/

/* ========== 圆角 ========== */
#moe-post-list .mdui-card.moe-default-card,
#moe-post-list .mdui-card.moe-nopic-card,
.mdui-card.moe-post-card,
.mdui-card.moe-comment-card,
.mdui-card.moe-comments-list-card,
.mdui-card.moe-archive-top-card,
.mdui-menu.mdui-menu-open,
.mdui-snackbar.mdui-snackbar-right-bottom,
#owoBox,
.hljs,
img.lazyload,
.aplayer,
.mdui-table-fluid,
.mdui-table-fluid .mdui-table,
.mdui-card.moe-search-result,
#search-dialog .moe-search-content.moe-search-content-focus form #searchInput,
.mdui-dialog.mdui-dialog-open:not(#search-dialog),
.mdui-card.moe-bangumi-card,
.mdui-card.moe-links-card,
.mdui-card.moe-404-card,
.mdui-dialog.mdui-dialog-alert,
.mdui-dialog.moe-login,
.mdui-card.moe-archive-card,
#owoBox main.moe-owo-main a.moe-owo-smallPicture-btn,
#owoBox main.moe-owo-main a.moe-owo-emoji-btn,
#owoBox main.moe-owo-main a.moe-owo-smallPicture-btn,
#owoBox main.moe-owo-main a.moe-owo-text-btn,
#owoBox main.moe-owo-main a.moe-owo-picture-btn,
#owoBox main.moe-owo-main a.moe-owo-picture-btn img {
  border-radius: 8px !important;
}

.moe-post-card .moe-card-content .moe-post-card-copy {
  border-radius: 1px 8px 8px 1px !important;
}

.mdui-btn:not(.mdui-btn-icon) {
  border-radius: 6px;
}

.newui-up-radius #moe-post-list .mdui-card.moe-default-card,
.newui-up-radius #moe-post-list .mdui-card.moe-nopic-card,
.newui-up-radius .mdui-card.moe-post-card,
.newui-up-radius .mdui-card.moe-comment-card,
.newui-up-radius .mdui-card.moe-comments-list-card,
.newui-up-radius .mdui-card.moe-archive-top-card,
.newui-up-radius .mdui-menu.mdui-menu-open,
.newui-up-radius .mdui-snackbar.mdui-snackbar-right-bottom,
.newui-up-radius #owoBox,
.newui-up-radius .hljs,
.newui-up-radius img.lazyload,
.newui-up-radius .aplayer,
.newui-up-radius .mdui-table-fluid,
.newui-up-radius .mdui-table-fluid .mdui-table,
.newui-up-radius .mdui-card.moe-search-result,
.newui-up-radius
  #search-dialog
  .moe-search-content.moe-search-content-focus
  form
  #searchInput,
.newui-up-radius .mdui-dialog.mdui-dialog-open:not(#search-dialog),
.newui-up-radius .mdui-card.moe-bangumi-card,
.newui-up-radius .mdui-card.moe-links-card,
.newui-up-radius .mdui-card.moe-404-card,
.newui-up-radius .mdui-dialog.mdui-dialog-alert,
.newui-up-radius .mdui-dialog.moe-login,
.newui-up-radius .mdui-card.moe-archive-card,
.newui-up-radius #owoBox main.moe-owo-main a.moe-owo-smallPicture-btn,
.newui-up-radius #owoBox main.moe-owo-main a.moe-owo-emoji-btn,
.newui-up-radius #owoBox main.moe-owo-main a.moe-owo-smallPicture-btn,
.newui-up-radius #owoBox main.moe-owo-main a.moe-owo-text-btn,
.newui-up-radius #owoBox main.moe-owo-main a.moe-owo-picture-btn,
.newui-up-radius #owoBox main.moe-owo-main a.moe-owo-picture-btn img {
  border-radius: 12px !important;
}

.newui-up-radius .moe-post-card .moe-card-content .moe-post-card-copy {
  border-radius: 1px 12px 12px 1px !important;
}

@media (max-width: 600px) {
  .mdui-card.moe-post-card,
  .mdui-card.moe-comment-card,
  .mdui-card.moe-comments-list-card,
  .newui-up-radius .mdui-card.moe-post-card,
  .newui-up-radius .mdui-card.moe-comment-card,
  .newui-up-radius .mdui-card.moe-comments-list-card,
  .mdui-snackbar.mdui-snackbar-right-bottom,
  .newui-up-radius .mdui-snackbar.mdui-snackbar-right-bottom {
    border-radius: 0px !important;
  }
}

@media (max-width: 530px) {
  .mdui-dialog.moe-login,
  .newui-up-radius .mdui-dialog.moe-login {
    border-radius: 0px !important;
  }
}

/* ========== 右下角按钮 ========== */
@media (max-width: 600px) {
  #btn-group .mdui-fab {
    width: 36px !important;
    min-width: 36px !important;
    height: 36px !important;
    font-size: 20px !important;
  }

  #btn-group .mdui-fab .mdui-icon {
    font-size: 20px !important;
    width: 20px !important;
    margin-top: 8px !important;
    margin-left: 8px !important;
    line-height: 20px !important;
  }

  #toc-Btn {
    bottom: 65px !important;
  }
}
